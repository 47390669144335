import { NextPage } from 'next';
import Router from 'next/router';
import { useEffect } from 'react';
import { logger } from '@/modules/logger';

const Home: NextPage = () => {
  useEffect(() => {
    Router.replace('/redirect').catch(logger.error);
  }, []);

  return <div />;
};
export default Home;
